/* LOADER */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dadada !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #dadada !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #dadada !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #dadada !important;
}

select::-ms-expand,
select::-ms-expand {
  display: none;
}

select,
select {
  -webkit-appearance: none;
  appearance: none;
}

.bg_gray {
  background: #dadada;
}

.bg_glow {
  animation: glow 1s infinite ease;
  transition: opacity 0.3 ease;
}

@keyframes glow {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.btn_facebook {
  border: solid 1px #3b5998 !important;
  background-color: #3b5998 !important;
  color: #ffffff !important;
}

.btn_google {
  border: solid 1px #db4437 !important;
  background-color: #db4437 !important;
  color: #ffffff !important;
}

.loader {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgb(51, 51, 51);
  z-index: 99999999;
  color: white;
  transition: all 0.25s ease;
}

.loader .logo {
  display: inline;
}

.loader span,
.loader strong {
  display: block;
}

/* * {
  font-family: 'Montserrat', sans-serif;
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  min-width: 320px;
  /* color:#252525; */
}

.montserrat {
  font-family: 'Montserrat', sans-serif !important;
}

.playfair {
  font-family: 'Playfair Display', sans-serif !important;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

option {
  padding: 0 50%;
}

select {
  /* background: pink; */
  text-align-last: center;
  text-align: center;
}
.dropdown {
  flex-grow: 1 !important;
}

.dropdown-menu {
  min-width: 50px !important;
  width: 100% !important;
  /* overflow: hidden!important; */
}

.filterDropDown {
  background-color: #fff !important;
  height: 38px !important;
  border-radius: 0 !important;
  border: 1px solid #252525 !important;
  box-sizing: border-box !important;
  color: #495057 !important;
  font-weight: 400;
  line-height: 1.5;
  width: 100% !important;
}

.fiterContainerDropDown.disabled,
.input-range--disabled,
.rc-pagination-disabled {
  opacity: .65;
}

.dropdown-item {
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: 100% !important;
  /* text-align: center!important; */
}

.dropdown-item.active,
.dropdown-item:active {
  color: #252525 !important;
  text-decoration: none;
  background-color: white !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.input_datepicker {
  background: url(assets/datePicker.svg) no-repeat right;
  background-color: white;
  background-position-x: 97%;
}

.input_fileUploader {
  background-color: white;
  font-size: 13px;
}

a {
  color: #252525;
}

.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.sideBar {
  height: 100%;
}

.nav-logo {
  height: 40px;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.categories {
  background: #1b71bd;
}

.img-categories {
  width: 150px;
  height: 150px;
  padding: 10px;
}

.top-nav {
  background: #1b71bd;
}

.top-nav-links {
  position: relative;
  bottom: 7px;
}

/* .navbar { */
/* min-height: 80px !important; */
/* } */

.navbar-nav {
  margin-top: 10px;
}

.nav-item {
  padding-bottom: 10px;
}

.nav-header-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  font-family: 'Raleway', sans-serif;
  color: #fff;
}

.nav-button {
  margin: 0 0 0 5px;
}

.content {
  /* padding-left: 60px; */
  -webkit-transition: padding .30s ease;
  transition: padding 0.3s ease;
}

.content.onexpand {
  padding-left: 250px;
}

.food-item {
  margin-bottom: 10px;
}

.breadcrumb-item {
  color: #1b71bd !important;
  cursor: pointer;
}

.breadcrumb-item.active {
  color: #333333 !important;
}

.product-img {
  width: 64px;
  height: 64px;
  overflow: hidden;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.construction {
  color: #fff;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: auto;
}

.construction-body {
  text-align: center;
}

.construction-body p {
  margin: 0;
}

.img-construction {
  width: 100px;
  margin-bottom: 20px;
}

.jumbotron {
  padding: 1rem 0;
}
.display-3 {
  font-size: 3.2rem !important;
}

.footer {
  background: white;
  font-size: 16px;
}

@media (max-width: 500px) {
  .content {
    padding-left: 0;
    /* padding-top: 56px; */
  }
}

.row-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  max-height: 200vh;
  overflow: hidden;
  vertical-align: middle;
}
.img-product {
  height: 200px;
  width: auto;
}
.column {
  flex: calc(100%/3);
  max-width: calc(100%/3);
  padding: 0 4px;
}

.column-whole {
  flex: 100%;
  max-width: 100%;
  padding: 0 4px;
  width: 100%;
}

.column-quarter {
  flex: 66.66666%;
  max-width: 66.66666%;
  padding: 0 4px;
}
.column-half {
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}

.column img {
  margin-top: 8px;
  height: 400px;
  width: 100%;
  vertical-align: middle;
}
.column-half img {
  margin-top: 8px;
  height: 400px;
  width: 100%;
  vertical-align: middle;
}
.column-quarter img {
  margin-top: 8px;
  height: 400px;
  width: 100%;
  vertical-align: middle;
}
.column-whole img {
  margin-top: 8px;
  height: 400px;
  width: 100%;
  vertical-align: middle;
}

@media (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
  .column-whole {
    flex: 50%;
    max-width: 50%;
  }
  .column-quarter {
    flex: 50%;
    max-width: 50%;
  }
  .column-half {
    flex: 50%;
    max-width: 50%;
  }
}

.css-vj8t7z {
  min-height: 38px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
  .column-whole {
    flex: 100%;
    max-width: 100%;
  }
  .column-half {
    flex: 100%;
    max-width: 100%;
  }
  .column-quarter {
    flex: 100%;
    max-width: 100%;
  }
}

.sideMenu {
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  position: fixed;
  z-index: 999;
  background: white;
  /* display: flex; */
  flex-direction: column;
  display: none;
}

.input {
  font-size: 18px !important;
}

.sideMenu li {
  cursor: pointer;
}

.sideMenuCategories {
  overflow: auto;
  margin: 0;
}

.sideMenuCategories > li {
  padding: 5px 20px;
}

.sideMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sideMenu a {
  color: black;
}

.sideMenuHeader {
  background: #1b71bd;
  min-height: 50px;
  color: white;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0 20px;
}

.product-name {
  font-size: 1em;
  font-weight: 400;
  color: #252525;
}

.brand-name {
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #c3a04b;
}
.brand-name-small {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #c3a04b;
}

.itemCurrent {
  color: #252525 !important;
  font-size: 12px;
  white-space: nowrap;
}
.before-price {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  text-align: center;

  color: #ffffff !important;
  background: #252525;

  padding: 1px 3.5px;
  white-space: nowrap;
}
.item-brand-name-label {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #c3a04b !important;
}
.item-product-name-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #252525 !important;
}
.itemDiscount {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-decoration-line: line-through;
  color: #252525;
  display: block;
}
.item-common-label,
.item-estimated-price-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;

  color: #252525;
  display: block;
}
.text-center {
  text-align: center;
}

.filter-head {
  background-color: #bbc2d5 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 1.4rem 2rem;
}

.attr-name {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #252525;
}

.banner-text {
  font-family: 'Playfair Display', serif;
  font-size: 30pt;
  text-align: center;
}

.productCard_tile .itemAction_add {
  background: #c3a04b;
}

.custom-control-label::after {
  top: 0.1rem;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input ~ .custom-control-label::before {
  top: 2px;
}

.product-action-img {
  width: auto;
  height: 30px;
  padding: 5px;
}

.product-detail-brand {
  color: #c3a04b;
  text-transform: uppercase;
  font-size: 24px;
}

.product-detail-name {
  color: #252525;
  /* text-transform: uppercase; */
  font-size: 14px;
}

.product-det-container {
  height: auto;
  /* padding-top:120px; */
  padding-top: 98px;
}

.form-control.select-order {
  font-size: 18px;
  color: #252525;
  font-family: 'Poppins';
}

@media (min-width: 768px) {
  .product-det-container {
    height: auto;
    padding-top: 140px;
  }
  .bannerCarouselSection {
    height: 40vh !important;
  }
}

.bold-text {
  font-weight: bold !important;
}

.form-control {
  /* border: 1px solid #252525 !important; */
  min-height: 38px !important;
  border-radius: 0 !important;
  box-sizing: border-box !important;
}

.small-text {
  font-size: 12px;
}
.medium-text {
  font-size: 16px;
}
.large-text {
  font-size: 21px;
}
.font-large {
  font-size: large;
}
.font-larger {
  font-size: larger;
}
.font-x-large {
  font-size: x-large;
}
.font-xx-large {
  font-size: xx-large;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn {
  font-size: 18px !important;
}

.btn-addtoBag {
  color: white;
  background: #c3a04b;
  border-radius: 0;
  padding: 10px;
  width: 100%;
}
.btn-sellyours {
  color: #252525;
  background: #fff;
  border: 1px solid #252525;
  border-radius: 0;
  padding: 10px;
  width: 100%;
}

.product-detail-img {
  z-index: 1;
}

.img-thumb {
  min-height: 100px;
  min-width: 100px;
  max-height: 100px;
  max-width: 100px;
  object-fit: scale-down;
  font-family: 'object-fit: scale-down';
  /* display: none; */
}

.activeTab {
  border-bottom: solid 5px #252525;
  padding: 0px 0px 10px 0px;
  cursor: pointer;
}

.tab {
  border-bottom: solid 2px #c4c4c4;
  padding: 0px 0px 13px 0px;
  cursor: pointer;
}
.activeCondition {
  color: #f2994a;
  font-weight: bold;
}
.condition {
  font-weight: bold;
}
.detail-container {
  height: 100vh;
  overflow: scroll;
}
.detail-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.my-account-box {
  padding: 30px;
}

.account-nav {
  padding: 0px;
}

.section {
  padding: 0 0 10px 0;
}

ul.rc-pagination {
  margin: 0;
}

.rc-pagination-item-active {
  background-color: #252525;
  color: #fff;
  border-color: #252525;
}
.rc-pagination-item {
  cursor: pointer;
  border-radius: 0;
  min-width: 35px;
  height: 35px;
  border: 1px solid #252525;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  line-height: 32px;
}

.rc-pagination-item:hover {
  border: 1px solid #252525;
  color: #fff !important;
}

.rc-pagination-item:hover a {
  color: #252525 !important;
}
.rc-pagination-item-active:hover a {
  color: #fff !important;
}

.rc-pagination-prev,
.rc-pagination-next {
  border: 1px solid #252525;
  font-size: 18px;
  cursor: pointer;
  color: #252525;
  font-size: 25px;
  border-radius: 0;
  list-style: none;
  min-width: 35px;
  height: 35px;
  line-height: 30px;
  float: left;
  text-align: center;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev {
  line-height: 45px;
}
.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
  color: #252525 !important;
}
hr {
  border-top: 2px solid #dadada !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "●";
  font-size: 8px;
  position: relative;
  top: -10%;
}

/* .Toastify__toast {
  min-height: 56px!important;
  padding: 0 25px;
} */

/* .Toastify__toast--default{
  background: #323232!important;
  color: #FFFFFF!important;
} */

/* .Toastify__toast ,.Toastify__toast--Success { */
/* color: #5b4d87 !important; */
/* background: #fff !important; */
/* } */

.capitalize {
  text-transform: capitalize;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.section-box {
  padding: 20px;
  background: #f2f2f2;
  min-height: 142px;
}

.page-link {
  color: #252525;
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: .5rem 18px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #252525;
  border-color: #252525;
}

.table thead td {
  vertical-align: bottom;
  /* background-color: #f2f2f2; */
  border: none !important;
  font-weight: regular;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, .1);
}

.custom-control-input ~ .custom-control-label::before {
  border-radius: 2px !important;
  background-color: #ffffff !important;
  border: solid 1px #252525 !important;
}

.filter-input-disabled .custom-control-label::before {
  opacity: 0.8;
  border-radius: 2px !important;
  background-color: #ffffff !important;
  border: solid 1px #6c757d !important;
}

.filter-input-disabled .custom-control-input:checked ~ .custom-control-label::before {
  border: solid 1px #6c757d !important;
}

.zoom-container img {
  object-fit: contain;
  font-family: 'object-fit: contain';
}

.error-field {
  border: solid 1px #dc3545 !important;
}

.loader-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover_white_bold:hover {
  color: #fff !important;
  font-weight: bold;
}

.banner-img {
  object-fit: cover;
  font-family: 'object-fit: cover';
}

figure.img-container {
  margin: 0;
}

.img-container {
  height: 100%;
  width: 100%;
}

.img-container > img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  opacity: 0.5;
}
.carousel-control-next-icon {
  background-image: url(icons/chevron-right.svg) !important;
}

.carousel-control-prev-icon {
  background-image: url(icons/chevron-left.svg) !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 100px !important;
  height: 100px !important;
}

.search-result-header {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #252525;
}

.bg-search-dimiss {
  background-color: #333333;
}

.delete_icon {
  font-size: 20px;
  position: relative;
  margin-left: 10px;
  top: -2px;
  cursor: pointer;
}

.pagination-orders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

span.shopping-cart-empty,
p.shopping-cart-empty {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

span.shopping-cart-empty {
  font-size: 15px;
}

span.recommend-shopping-message {
  font-family: Playfair;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #252525;
  max-width: 664px;
}

.item-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.item-image-container:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.loader-container {
  width: 145px;
  height: 145px;
}

.item-image-container img {
  position: absolute;
  width: inherit;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.item-image-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.banner-main {
  height: 40vh;
}

.banner-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-backdrop span.main-title {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 85px;
  color: #ffffff;
  text-align: center;
}

/* Pagination */
.product-pagination {
  display: flex;
  align-items: center;
}

.brand-featured-tile {
  padding: 15px;
}

.all-brands-section span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #252525;
}

.product-pagination .item-tracker {
  flex-shrink: 1;
}
.product-pagination .main-pagination {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

select {
  width: 400px;
  text-align-last: center;
}

@media (min-width: 0px) and (max-width: 576px) {
  figure {
    margin: 0 0 0rem;
  }
  .pagination-orders {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mobile-center {
    align-self: center;
  }
  .product-pagination {
    flex-direction: column;
  }
  .product-pagination .main-pagination,
  .product-pagination .item-tracker {
    margin-bottom: 1em;
  }
  .product-det-container {
    height: auto;
    padding-top: 0px;
  }

  .banner-main {
    height: 200px;
  }

  .banner-backdrop span.main-title {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .img-container > img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    font-family: 'object-fit: cover';
  }
  .bannerCarouselSection {
    height: 220px !important;
  }
  .mainHeader_mid_left .logo {
    position: relative;
    left: 145%;
  }
  .mobile-pt {
    padding-top: 10px;
  }
  .section-box {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .tc_surface {
    /* font-size: 20px; */
  }

  h5.tc_accent {
    /* font-size:25px; */
  }

  .center-mobile {
    text-align: center;
  }

  .button-container {
    padding-top: 10px;
  }

  ol.breadcrumb {
    position: relative;
    transform: translate(50%);
    width: 50%;
  }
  .product-name {
    font-size: 13px;
    font-weight: 400;
  }
  .brand-name {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
  }
  .itemDiscount {
    font-size: 13px;
  }
  .itemCurrent {
    font-size: 18px;
  }

  .productCard_tile {
    padding: 5px;
  }
}
